import React, {useState, useCallback, useEffect} from 'react'
import { BsSearch } from 'react-icons/bs'
import {BsFillTrashFill} from 'react-icons/bs'
import {IoCloseSharp} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import useLeiding from '../../api/leiding'
import useTakken from '../../api/takken'
import useLeidingTak from '../../api/leidingTak'
import Loader from '../rest/Loader'





const LeidingList = () => {

    const [leiding, setLeiding] = useState([])
    const [takken, setTakken] = useState([])    
    const [filteredLeiding, setFilteredLeiding] = useState([]);
    const [loading, setLoading] = useState(false)


    // api
    const leidingApi = useLeiding();
    const takkenApi = useTakken();
    const leidingTakApi = useLeidingTak();


    // calling api
    const refreshData = useCallback(async () => {
        try{
            setLoading(true)
            const response = await leidingApi.getAllLeidingZonderImage();
            console.log(response);
            setLeiding(response)
            setFilteredLeiding(response)
            const response2 = await takkenApi.getAllTakken();
            setTakken(response2)
        }
        catch(error){
            console.log(error)
        }     
        finally{
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        refreshData()
    }, [refreshData])



    const filterLeiding = (e) => {
        const searchValue = e.target.value.toLowerCase();
        const filteredArray = leiding.filter((leider) => {
            // Check if the name contains the search value
            const nameMatches = leider.name.toLowerCase().includes(searchValue);
            
            // Check if any of the select options match the search value
            const selectMatches = leider.takken.some((tak) =>
                tak.tak_name.toLowerCase().includes(searchValue)
            );
            
            return nameMatches || selectMatches;
        });
    
        setFilteredLeiding(filteredArray);
    }
    

    const handleDeleteTak = async (index) => {
        try {
            await leidingTakApi.deleteLeidingTak(index);
            await refreshData();
        } catch (error) {
            console.log(error);
        }
    }

    const addNewTak = async (leider) => {
        const values = {
            leiding_id: leider.id,
            tak_id: 1
          };
          try {
            await leidingTakApi.insertLeidingTak(values);
            await refreshData();
          } catch (error) {
            console.log(error);
          }
    }

    const updateTak =  async (leider, index, refid) => {
        const values = {
            leiding_id: leider.id,
            tak_id: index
        };
        try {
            await leidingTakApi.updateLeidingTak(refid, values);
            await refreshData();
        } catch (error) {
        console.log(error);
        }

    }

    const handleDeleteLeiding = async (index) => {
        try {
            await leidingApi.deleteLeiding(index);
            await refreshData();
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div >
            <div className='upperPart-admin'>
                <div className='searchbar'>
                    <BsSearch className='searchIcon' />
                    <input className='searchbar-input' type="text" placeholder="Zoek leiding..." onChange={filterLeiding} />
                </div>
                <Link to="/admin/leiding/add" className='btn-save'>
                    Add +
                </Link>
            </div>          
            <div className='table-container'>
            <table className='leiding-list'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Tak</th>
                        <th>Telefoon</th>
                        <th></th>
                        
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <Loader />
                    ) : (
                        filteredLeiding.map((leider, index) => {
                        return(
                            <tr key={index} className={`row-${index % 2 === 0 ? 'even' : 'odd'}`} >
                            <td>{leider.id}</td>
                         <td>{leider.name}</td>
                         <td className='takkenColum'>
                          {leider.takken.map((tak, index) => (
                             <div className='holeselect'>
                             <select className={`takRow ${tak.tak_name}`} onChange={(e) => updateTak(leider,  e.target.value, tak.tak_leiding_id)} key={index}> 
                             {takken.takken.map((takvast, index) => (
                                     <option key={index} selected={takvast.name === tak.tak_name ? true : false} value={takvast.id}>{takvast.name}</option>
                                 ))}
                             </select>
                             {
                                leider.takken.length > 1 ?  <div className={`uppericonselect ${tak.tak_name}`} onClick={() => handleDeleteTak(tak.tak_leiding_id)}>
                                <IoCloseSharp  />
                            </div> : <div className={`corners ${tak.tak_name}`}></div>
                             }
                            
                             </div>
                         ))}

                         <div className='addTak' onClick={() => addNewTak(leider)}>
                             +
                         </div>
                         </td>
                         <td>{leider.tel}</td>
                     <td className='action-table'><BsFillTrashFill onClick={() => handleDeleteLeiding(leider.id)} className='deletetrash'/></td>              
                 </tr>
                        )
                    }
                    ))}
                </tbody>
            </table>
            </div>
        </div>
    );
}

export default LeidingList;